import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"

import Header from "./Header";
import SiteFooter from "./Footer";
import Influencers from './InfluencersPage';
import Home from './Home';

function index() {
    return (
        <div>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="influencers" element={<Influencers />} />
                </Routes>
                <SiteFooter />
            </Router>
        </div>
    )
}

export default index