import React from "react";
import PropTypes from "prop-types"
import "./WorldCardInfluencer.css";

class WorldCardInfluencer extends React.Component {
    static get propTypes() {
        return {
            name: PropTypes.string,
            creator: PropTypes.string,
            image: PropTypes.string,
            worldcode: PropTypes.number,
            scale: PropTypes.string,
            class: PropTypes.string,
        }
    }

    render() {
        return (
            <div className={"wci-container " + this.props.class} style={{"--scale": this.props.scale}}>
                <div className="wci" style={{"--image": "url(\"../../" + this.props.image + "\")"}}>
                    <p className="wci-name">{this.props.name}</p>
                    <p className="wci-creator subtext">By {this.props.creator}</p>
                    <p className="wci-worldcode">{this.props.worldcode}</p>
                </div>
            </div>
        )
    }
}

export default WorldCardInfluencer