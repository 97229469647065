import React from "react";
import "./Influencers.css";

import WorldCard from './WorldCardInfluencer'

function influencers() {
    return (
        <div>
            <div className="banner-container">
                <video className="banner-video" src="videos/home-banner.mp4" autoPlay muted loop></video>
                <div className="banner-items">
                    <p className="banner-title uppercase">Influencers</p>
                </div>
            </div>
            <section className="influencer-info">
                <div className="influencer-left">
                    <WorldCard name="The Core" creator="epicred51" worldcode="5150209944" image="images/stat-bubble/7.png" class="wci1" scale="0.9" />
                    <WorldCard name="Carol of a Dark Christmas" creator="Mio2y" worldcode="5941552932" image="images/stat-bubble/3.png" class="wci2" scale="1.1" />
                </div>
                <div className="influencer-center">
                    <h1 className="influencer-header uppercase">Influencers</h1>
                    <p className="influencer-paragraph">If you are an influencer with a large stream, YouTube channel, or popular social media channels, you might be eligible for our Influencer role.</p>
                    <p className="influencer-paragraph">As an Influencer on World Builder, you will gain a special role in our Discord server and our guilded server where you can share your content and interact with our community. This role will also grant you access to an exclusive ‘Influencer’ channel where you can interact with other influencers and contact our team, from our Moderators who can assist you with issues to our Developers who can answer game-related questions.</p>
                    <p className="influencer-paragraph">We’re also in the process of working out a special in-game role that will provide special perks for influencers!</p>
                    <p className="influencer-paragraph">To apply for the Influencer role, fill out the form linked below and we’ll get in touch with you via Discord or Guilded if you’re successful. We will only be inviting a small number of influencers into our program at this time.</p>
                    <p className="influencer-paragraph">YouTube channels must have at least 100 subscribers to qualify for the role at this time.</p>
                    <a className="influencer-form" href="https://forms.gle/WmSHpoMFv9qhSeXP6">APPLY HERE</a>
                </div>
                <div className="influencer-right">
                    <WorldCard name="Castle in the Sky" creator="NyamusChan" worldcode="6525242101" image="images/stat-bubble/4.png" class="wci3" scale="1.17" />
                    <WorldCard name="Shooting Range" creator="Jaigyo" worldcode="7095062218" image="images/stat-bubble/5.png" class="wci4" scale="1" />
                    <WorldCard name="Boss Fighting Simulator" creator="NyamusChan" worldcode="5611538858" image="images/stat-bubble/6.png" class="wci5" scale="0.62" />
                </div>
            </section>
        </div>
    )
}

export default influencers