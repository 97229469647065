import React from "react";
import "./Footer.css";

function sitefooter() {
    return (
        <div className="site-footer">
            <div className="footer-left">
                <p className="ambishyon uppercase">Ambishyon</p>
                <p className="copyright subtext">&copy;2021 Ambishyon. All Rights Reserved</p>
            </div>
            <div className="footer-right">
                <a href="https://guilded.wbga.me"><img className="guilded social-icon" src="svg/guilded.svg" /></a>
                <a href="https://discord.wbga.me"><img className="discord social-icon" src="svg/discord.svg" /></a>
                <a href="https://twitter.com/RBXWorldBuilder"><img className="twitter social-icon" src="svg/twitter.svg" /></a>
            </div>
        </div>
    )
}

export default sitefooter