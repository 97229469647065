import React from "react";
import "./Home.css";

import StatBubble from "./StatBubble";

function home() {
    return (
        <div>
            <div className="banner-container">
                <video className="banner-video" src="videos/home-banner.mp4" autoPlay muted loop></video>
                <div className="banner-items">
                    <img className="home-logo" src="images/logo.png"></img>
                    <a className="play-now" href="https://play.wbga.me">PLAY NOW</a>
                </div>
            </div>
            <section className="trailer-embed">

            </section>
            <section className="what-is-wb">
                <h1 className="uppercase">What is <div className="text-primary inline">World Builder</div>?</h1>
                <p className="first-paragraph">Explore a wide variety of worlds made by other players or create your own worlds using over 100 unique blocks to create detailed builds or impressive and complex mechanisms using our wiring blocks! In World Builder, there is a limitless amount of possibilities waiting to be discovered!</p>
                <p className="second-paragraph">World Builder is available for free to play on the Roblox platform for PC, Xbox One, phones, tablets, and VR.</p>
            </section>
            <section className="stats">
                <StatBubble text="Players Online" count="38" scale="18em" left="0" top=".35em" />
                <StatBubble text="Active Worlds" count="8" scale="9.5em" left="5em" top="14em" />
                <StatBubble text="Total Worlds" count="76000" scale="11em" left="6em" top="3em" />
                <StatBubble text="Featured Worlds" count="0" scale="20em" left="9em" top="5.4em" />
            </section>
            <section className="play-paragraph">
                <p className="third-paragraph">Play now to join an ever-expanding metaverse full of thousands of unique worlds to explore and create your own using our powerful, easy to use tools that empower you to use your imagination to its fullest extents!</p>
            </section>
        </div>
    )
}

export default home