import React from "react";
import PropTypes from "prop-types"
import "./StatBubble.css";

const images = 11
var prev = []

function gen() {
    var res = 1;
    do {
        res = Math.floor(Math.random() * images) + 1;
    } while (prev.includes(res));
    if (prev.length > 3) {
        prev.pop();
    }
    prev.push(res);
    return res
}

function abbreviate(number, maxPlaces, forcePlaces, forceLetter) {
    number = Number(number)
    forceLetter = forceLetter || false
    if(forceLetter !== false) {
      return annotate(number, maxPlaces, forcePlaces, forceLetter)
    }
    var abbr
    if(number >= 1e12) {
      abbr = 'T'
    }
    else if(number >= 1e9) {
      abbr = 'B'
    }
    else if(number >= 1e6) {
      abbr = 'M'
    }
    else if(number >= 1e3) {
      abbr = 'K'
    }
    else {
      abbr = ''
    }
    return annotate(number, maxPlaces, forcePlaces, abbr)
  }
  
  function annotate(number, maxPlaces, forcePlaces, abbr) {
    // set places to false to not round
    var rounded = 0
    switch(abbr) {
      case 'T':
        rounded = number / 1e12
        break
      case 'B':
        rounded = number / 1e9
        break
      case 'M':
        rounded = number / 1e6
        break
      case 'K':
        rounded = number / 1e3
        break
      case '':
        rounded = number
        break
    }
    if(maxPlaces !== false) {
      var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$')
      if(test.test(('' + rounded))) {
        rounded = rounded.toFixed(maxPlaces)
      }
    }
    if(forcePlaces !== false) {
      rounded = Number(rounded).toFixed(forcePlaces)
    }
    return rounded + abbr
  }

class StatBubble extends React.Component {
    static get propTypes() {
        return {
            text: PropTypes.string,
            count: PropTypes.number,
            scale: PropTypes.string,
            top: PropTypes.string,
            left: PropTypes.string,
        }
    }

    render() {
        const num = gen()
        return (
            <div className="stat-bubble-container">
                <div className="stat-bubble" style={{"--scale": this.props.scale, "background-image": "url(\"images/stat-bubble/" + num + ".png\")", "margin-top": this.props.top, "margin-left": this.props.left}}>
                    <p className="stat-count uppercase text-primary">{abbreviate(this.props.count, 2, false, false)}</p>
                    <p className="stat-title uppercase">{this.props.text}</p>
                </div>
            </div>
        )
    }
}

export default StatBubble