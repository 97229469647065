import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

function header() {
    return (
        <div className="header">
            <Link to="/" className="header-logo-container"><img src="images/logo-small.png" className="header-logo"></img></Link>
            <nav className="header-navbar">
                <Link to="/influencers" className="header-navitem uppercase">Influencers</Link>
            </nav>
        </div>
    )
}

export default header